import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { getReportData, getReportsList } from '../../../../actions/reports'
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside'
import cn from 'classnames'
import Loader from '../../../../components/Loader'
import { getCertificationRegisterReport, getQuantityReferenceReport, getRatingByCategoryReport } from './DocCreaters'
import moment from 'moment'
import { getProtocol } from './DocCreaters/protocol'

const REPORT_NAMES = {
  certification_register: 'Реестр педагогических работников, подавших заявления для прохождения добровольной аттестации',
  quantity_reference: 'Справка по результатам экспертизы аттестационных материалов',
  rating_by_category: 'Справка-рейтинг по итогам экспертизы аттестационных материалов',
  protocol: 'Протокол'
}

const INITIAL_STATUS_COUNTS = {equal: 0, reduced: 0, updgraded: 0, undefined: 0}

export default function Reports () {
  
  const dispatch = useDispatch()
  const { comission, userInfo } = useSelector(state => state.auth)
  const { name, list, data, loading } = useSelector(state => state.reports)
  const popUpRef = useRef(null)

  const [popUpOpen, setPopUpOpen] = useState(false)
  const [reportName, setReportName] = useState('')
  
  useOnClickOutside(popUpRef, () => setPopUpOpen(false))

  useEffect(() => {
    dispatch(getReportsList())
  }, [])
  
  useEffect(() => {
    if (name !== reportName) return;
    const { callback, title } = getReportProps(reportName)
    createPdfDoc(callback, title)
    setPopUpOpen(false)
    setReportName('')
  }, [reportName, data])
  

  const getCurrentReport = (reportName) => async () => {
    setReportName(reportName)
    await dispatch(getReportData(reportName))
    
  }

  const getReportProps = (reportName) => {
    let callback, title
    switch (reportName) {
      case 'certification_register':
        callback = getCertificationRegisterReport
        break;
      case 'quantity_reference':
        callback = getQuantityReferenceReport
        break;
      case 'protocol':
        callback = getProtocol
        break;
      default:
        callback = getRatingByCategoryReport
        break;
    }
    title = REPORT_NAMES[reportName]
    return { callback, title }
  }

  const createPdfDoc = (createDataCB, title) => {
    createDataCB({data, comission, userInfo}).then(doc => doc.save(`${title}.pdf`))
  }

  const togglePopUp = () => setPopUpOpen(!popUpOpen)

  const renderLi = ({uri_report_name}) => <li onClick={getCurrentReport(uri_report_name)}>{REPORT_NAMES[uri_report_name]}</li>

  const certificationRegisterTable = () => (
    <table  hidden id='certificationRegisterTable'>
      <thead>
        <tr>
          <th>№ п/п</th>
          <th>№ п/п по ДОУ</th>
          <th>Наименование образовательного учреждения</th>
          <th>ФИО аттестуемого</th>
          <th>Категория по заявлению</th>
          <th>Должность</th>
          <th>Месяц проведения аттестации</th>
          <th>Количество баллов по портфолио / награды</th>
          <th>Примечание/Отметка о поступлении</th>
        </tr>
        <tr>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
          <td>5</td>
          <td>6</td>
          <td>7</td>
          <td>8</td>
          <td>9</td>
        </tr>
      </thead>
      <tbody>
        {data && Object.keys(data).map((orgName, index) => {
          const reqCount = data[orgName].length
          const groupIndex = Object.values(data).slice(0, index).reduce((accum, group) => accum + group.length, 0)
          return (
            <>
              {data[orgName].map((req, index) => {
                const attestationDate = req.attestation_date && moment(req.attestation_date).format('MMMM YYYY')
                const completed = req.status == 6 ? 'Соответствует' : 'Не соответствует'
                const pointsAwards = req.simple_cert ? req.awards?.map(({name}) => name).join(', ') : req.user_points
                
                return (
                  <tr>
                    <td>{groupIndex + index + 1}</td>
                    <td>{index + 1}</td>
                    {!index && <td rowSpan={reqCount}>{orgName}</td>}
                    <td>{req.full_user_name}</td>
                    <td>{req.category}</td>
                    <td>{req.attestation_name}</td>
                    <td>{attestationDate}</td>
                    <td>{pointsAwards}</td>
                    <td>{completed}</td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan={9}>{`Итого: ${reqCount} педагогических работника`}</td>
              </tr>
            </>
          )
        })}
      </tbody>
    </table>
  )

  const quantityReferenceTable = () => (
    <table  hidden id='quantityReferenceTable'>
      <thead>
        <tr>
          <th rowSpan={2}>№ п/п</th>
          <th rowSpan={2}>Название образовательного учреждения</th>
          <th rowSpan={2}>Общее количество представлен ных портфолио</th>
          <th colSpan={2}>Количество представленных портфолио</th>
          <th colSpan={2}>По итогам экспертизы подтверждено</th>
          <th colSpan={2}>Не аттестовано</th>
        </tr>
        <tr>
          <th>высшая КК</th>
          <th>первая КК</th>
          <th>высшая КК</th>
          <th>первая КК</th>
          <th>высшая КК</th>
          <th>первая КК</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((org, index) => (
          <tr>
            {org.org_name && <td>{index+1}</td>}
            {org.org_name ? <td>{org.org_name}</td> : <td colSpan={2}>Итого</td>}
            <td>{org.all_users}</td>
            <td>{org.first_cat}</td>
            <td>{org.highest_cat}</td>
            <td>{org.confirmed_first_cat}</td>
            <td>{org.confirmed_highest_cat}</td>
            <td>{org.cancelled_first_cat}</td>
            <td>{org.cancelled_highest_cat}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  const ratingByCategoryTable = (data) => (
    <table  hidden id='ratingByCategoryTable'>
      <thead>
        <tr>
          <th>№ п/п</th>
          <th>Образовательное учреждение</th>
          <th>ФИО педагога</th>
          <th>Должность</th>
          <th>КК</th>
          <th>Количество баллов в ДОУ</th>
          <th>Кол-во баллов по рез-ам экспертизы</th>
        </tr>
      </thead>
      <tbody>
        {data && Object.keys(data).map(position => {
          // const firstCatInclude = !!data[position].find((req) => req.category === 'первая')
          // const highCatInclude = !!data[position].find((req) => req.category === 'высшая')
          const firstCat = data[position].filter((req) => req.category === 'первая')
          const highCat = data[position].filter((req) => req.category === 'высшая')

          const renderCategory = (list, highCat) => {
            if (!list.length) return null
            const pointsStatus = {...INITIAL_STATUS_COUNTS}
            list.forEach((req) => pointsStatus[req.points_status] += 1)
            const { equal, reduced, updgraded } = pointsStatus
            const getPercent = (count) => Math.round(count / list.length * 100) + '%'

            return (
              <>
                <tr>
                  <td colSpan={7}>{`${highCat ? 'Высшая' : 'Первая' } категория`}</td>
                </tr>
                {list.map((req, index) => (
                    <tr>
                      <td>{index+1}</td>
                      <td>{req.organization_name}</td>
                      <td>{req.full_user_name}</td>
                      <td>{req.position_name}</td>
                      <td>{req.attestation_name}</td>
                      <td>{req.comission_points}</td>
                      <td>{req.expert_points}</td>
                    </tr>
                ))}
                <tr>
                  <td colSpan={7}>Количество {list.length}</td>
                </tr>
                <tr>
                  <td colSpan={7}>соответствие выставленных баллов {equal} / {getPercent(equal)}</td>
                </tr>
                <tr>
                  <td colSpan={7}>баллы повышены {updgraded} / {getPercent(updgraded)}</td>
                </tr>
                <tr>
                  <td colSpan={7}>баллы снижены {reduced} / {getPercent(reduced)}</td>
                </tr>
              </>)
          }

          return (
            <>
              <tr>
                <td colSpan={7}>{position}</td>
              </tr>
              {renderCategory(highCat, 'высшая')}
              {renderCategory(firstCat)}
            </>
          )
        })}
      </tbody>
    </table>
  )

  const ratingByCategorySecondTable = (data) => {
    return (
      <table  hidden id='ratingByCategorySecondTable'>
        <thead>
          <tr>
            <th></th>
            <th>Количество</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Имели высшую - идут на высшую</td>
            <td>{data.high_high}</td>
            <td>{data.percent_high_high}</td>
          </tr>
          <tr>
            <td>Имели первую - идут на высшую</td>
            <td>{data.first_high}</td>
            <td>{data.percent_first_high}</td>
          </tr>
          <tr>
            <td>Имели первую - идут на первую</td>
            <td>{data.first_first}</td>
            <td>{data.percent_first_first}</td>
          </tr>
          <tr>
            <td>Без категории - идут на первую</td>
            <td>{data.none_first}</td>
            <td>{data.percent_none_first}</td>
          </tr>
          <tr>
            <td>Имели высшую - идут на первую</td>
            <td>{data.high_first}</td>
            <td>{data.percent_high_first}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <div ref={popUpRef} className={styles.popUpWrap}>

      <Button label={"Отчеты"} onClick={togglePopUp}/>

      <ul className={cn(styles.popUp, {[styles.popUpOpen]: popUpOpen})}>
        {loading ? <Loader wrapperStyle={{marginTop: '0'}}/> : list?.map(renderLi)}
      </ul>

      {name==='certification_register' && certificationRegisterTable()}
      {name==='quantity_reference' && quantityReferenceTable()}
      {name==='rating_by_category' && ratingByCategoryTable(data.teachers_list)}
      {name==='rating_by_category' && ratingByCategorySecondTable(data.total_list)}
    </div>
  )
}
